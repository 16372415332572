<template>
  <div class="row justify-content-center h-100">
    <div class="holder" :style="[width ? {height: width, width: width} : {height: height, width: height}]">
      <div class="overlay"></div>
      <div class="col-12 p-0 h-100">
        <div class="row justify-content-center h-100">
          <div class="col-12 h-75">
            <lottie-player
              src="https://assets7.lottiefiles.com/packages/lf20_qzsgm3gz.json"
              background="transparent"
              speed="1"
              style="width: 100%;"
              loop
              autoplay
            >
            </lottie-player>
          </div>
          <div class="col-auto h-25">
            <img src="@/assets/images/Gift-Aid-Logo-Green.png" class="img-fluid giftaid_img">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GiftAidAnimation',
  props: ['height', 'width']
}
</script>

<style scoped>
  .holder {
    background-position: center center;
    background-color: #f4f4f4;
    background-image: url(../../assets/images/p.png);
    border-radius: 50%;
    width: 100%;
    position: relative;
    clip-path: circle(50%);
    padding: 0;
  }
  .overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg, #F4F4F4DB 0%, #F4F4F4 100%);
    opacity: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
  }
  .giftaid_img {
    height: 70px;
  }
</style>
